import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { DeleteSharp, GetAppRounded, Visibility } from "@material-ui/icons";
import React, { Fragment, useCallback, useEffect } from "react";
import { fileService } from "@dwo/shared/dist/services/fileService";
import Popover from "@material-ui/core/Popover";

interface ExpenseActionIconsProps {
  expenseId: string;
  fileId?: number;
  onClickDelete: (expenseId: string) => void;
  onClickDownload: (fileId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main,
    },
    iconButton: {
      padding: "8px",
    },
    previewImage: {
      width: "200px",
      padding: 12,
    },
  }),
);

export function ExpenseActionIcons({
  expenseId,
  fileId,
  onClickDelete,
  onClickDownload,
}: ExpenseActionIconsProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState("");

  const fetchImageUrl = useCallback(async () => {
    try {
      const { data } = await fileService.getById(String(fileId), {});
      return data?.url || "";
    } catch (error) {
      return "";
    }
  }, [fileId]);

  useEffect(() => {
    fetchImageUrl()
      .then((url) => setImageUrl(url))
      .catch((error) => console.log(error));
  }, [fetchImageUrl]);

  const handleClickDelete = (id: string) => () => {
    onClickDelete(id);
  };

  const handleClickDownload = (id?: number) => () => {
    if (id) {
      onClickDownload(id);
    }
  };

  const handleOpenPrev = async (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="flex-end"
      width="100%"
      marginLeft="-8px"
      maxWidth="120px"
    >
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <img className={classes.previewImage} src={imageUrl} alt={"Preview"} />
      </Popover>
      {fileId != null && (
        <Fragment>
          <IconButton
            aria-label="Preview Expense"
            className={classes.iconButton}
            onClick={handleOpenPrev}
          >
            <Visibility className={classes.icon} />
          </IconButton>
          <IconButton
            aria-label="Download Expense"
            className={classes.iconButton}
            onClick={handleClickDownload(fileId)}
          >
            <GetAppRounded className={classes.icon} />
          </IconButton>
        </Fragment>
      )}

      <IconButton
        aria-label="Delete Expense"
        className={classes.iconButton}
        onClick={handleClickDelete(expenseId)}
      >
        <DeleteSharp className={classes.icon} />
      </IconButton>
    </Box>
  );
}
