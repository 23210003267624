import {
  Box,
  createStyles,
  Divider,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { CheckboxCustom } from "components/CheckboxCustom";
import { ExpenseValues } from "components/jobDetails/summary/AddExpensesModal";
import { SelectCustom } from "components/jobForm/SelectCustom";
import { UploadInput } from "components/UploadInput";
import { useFormik } from "formik";
import { noop } from "lodash/fp";
import React, { useEffect, useState } from "react";
import { DEFAULT_SELECT_OPTION } from "utils/jobFormUtils";
import { FileData } from "utils/sharedUtils";
import * as Yup from "yup";

interface ExpenseFormProps {
  uniqueId: string;
  handleFormikValues: (value: ExpenseValues) => void;
}

interface StyleProps {
  doesFormHaveErrors?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helpText: {
      color: theme.palette.grey["400"],
      width: "70%",
      marginTop: "10px",
      marginBottom: "7px",
    },
    checkboxText: {
      color: theme.palette.grey["700"],
      fontWeight: "bold",
    },
    inputField: {
      "& label + .MuiInput-formControl": {
        marginTop: 0,
        height: "100%",
      },
    },
    textFieldRoot: {
      height: "auto",
      "&> .MuiInputLabel-root": {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        fontWeight: "bold",
      },
      "& .MuiInputAdornment-positionStart": {
        margin: "-2px -4px 0 8px",
      },
    },
    multilineTextFieldRoot: {
      height: "100%",
      "&> .MuiInputLabel-root": {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        fontWeight: "bold",
      },
    },
    divider: {
      marginTop: ({ doesFormHaveErrors }: StyleProps) =>
        doesFormHaveErrors ? "75px" : "16px",
      marginBottom: "16px",
    },
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
    requiredLabel: {
      color: theme.palette.common.black,
      fontSize: 12,
      paddingBottom: 4
    },
  }),
);

export function ExpenseForm({
  handleFormikValues,
  uniqueId,
}: ExpenseFormProps) {
  const [file, setFile] = useState<FileData | undefined>(undefined);
  const [isReBillable, setIsReBillable] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      description: "",
      amount: "0",
      type: DEFAULT_SELECT_OPTION,
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .required("Description is required")
        .test("empty", "Description is required", (address) => !!address),
      amount: Yup.number().min(0).required("Amount is required"),
      type: Yup.string()
        .required("Type is required")
        .test(
          "empty",
          "Description is required",
          (type) => type !== DEFAULT_SELECT_OPTION,
        ),
    }),
    initialErrors: {
      description: "",
      amount: "0",
      type: DEFAULT_SELECT_OPTION,
    },
    onSubmit: noop,
  });

  const doesFormHaveErrors =
    (formik.touched.description && Boolean(formik.errors.description)) ||
    (formik.touched.amount && Boolean(formik.errors.amount)) ||
    (formik.touched.type && Boolean(formik.errors.type));
  const classes = useStyles({ doesFormHaveErrors });

  useEffect(() => {
    handleFormikValues({
      file,
      values: formik.values,
      errors: !formik.isValid,
      isReBillable,
      id: uniqueId,
    });
  }, [
    doesFormHaveErrors,
    handleFormikValues,
    formik,
    uniqueId,
    file,
    isReBillable,
  ]);

  const handleFileUpload = (file: FileData) => setFile(file);
  const handleClickCheckbox = () => setIsReBillable(!isReBillable);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box marginTop="16px">
        <UploadInput
          accept=".pdf, .png, .jpg, .jpeg"
          id="upload-expense-file"
          name="uploadImage"
          text="Select File"
          maxSize={10000000}
          label="Upload Field"
          onUploadFiles={handleFileUpload}
        />
        <Typography variant="body2" className={classes.helpText}>
          Use a PDF, PNG or JPG file with size below of 200KB. We recommend a
          size of 678x456px.
        </Typography>
      </Box>
      <Box display="flex" height="114px">
        <Box flex={1}>
          <Typography className={classes.requiredLabel} variant="body2">
            * Required field
          </Typography>
          <TextField
            value={formik.values.description}
            className={classes.inputField}
            classes={{ root: classes.multilineTextFieldRoot }}
            fullWidth
            id="description"
            multiline
            label="Description"
            placeholder="Write a description"
            rows={3}
            rowsMax={3}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.description && Boolean(formik.errors.description) && (
            <Typography className={classes.errorMessage} variant="body2">
              Description is Required
            </Typography>
          )}
        </Box>
      </Box>
      <Divider className={classes.divider} />
    </form>
  );
}
