import {
  Box,
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { RangeDatePicker } from "components/RangeDatePicker";
import { TableCustom } from "components/table/TableCustom";
import {
  deleteJobExpense,
  getAllJobExpenses,
  getJobExpenseFile,
  selectExpenseFile,
  selectIsLoading,
  selectIsLoadingDeleting,
  selectJobExpenses,
  setExpenseFile,
} from "features/jobs/jobExpenses/jobExpensesSlice";
import {
  prompt,
  selectResponse,
  selectShow,
} from "features/prompt/promptSlice";
import { cloneDeep } from "lodash";
import { DateRange } from "materialui-daterange-picker";
import { FileModel } from "@dwo/shared/dist/models/fileModel";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  expensesModalColumns,
  getExpensesModalRows,
  initialJobDetailsQuery,
} from "utils/jobDetailsUtils";
import { DEBOUNCE_TIME_MS } from "utils/sharedUtils";

interface ExpensesHistoryModalProps {
  jobId: string;
  isOpen: boolean;
  onClickClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    idDateContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "-8px",
      padding: "0 24px 16px 24px",
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
        flexDirection: "column",
      },
    },
    jobId: {
      color: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        marginBottom: "24px",
      },
    },
    searchbarTotalContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      padding: "0 24px 24px 24px",
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
        flexDirection: "column",
      },
    },
    totalLabel: {
      color: theme.palette.grey[700],
      fontWeight: "bold",
      marginRight: "24px",
    },
    totalValue: {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  }),
);

export function ExpensesHistoryModal({
  jobId,
  isOpen,
  onClickClose,
}: ExpensesHistoryModalProps) {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const jobQueryId = parseInt(id, 10);
  const expenses = useSelector(selectJobExpenses);
  const isDeleteModalOpen = useSelector(selectShow);
  const expenseFile = useSelector(selectExpenseFile);
  const isLoadingData = useSelector(selectIsLoading);
  const isLoadingDeleting = useSelector(selectIsLoadingDeleting);
  const promptResponse = useSelector(selectResponse);
  const [deleteExpenseId, setDeleteExpenseId] = useState<string>("");
  const [downloadId, setDownloadId] = useState<number>(-1);
  const [previewId, setPreviewId] = useState<number>(-1);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [query, setQuery] = useState<ServiceOptions>({
    where: { jobId: jobQueryId },
    include: ["createdBy"],
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);
  const classes = useStyles();

  const isLoading = isLoadingData || isLoadingDeleting;

  useEffect(() => {
    if (isOpen) {
      dispatch(getAllJobExpenses(query));
    }
  }, [isOpen, query, dispatch]);

  useEffect(() => {
    if (!isSearching) {
      return;
    }

    const queryHandler = setTimeout(() => {
      setQuery((prevQuery) => {
        const oldQuery = cloneDeep(prevQuery);
        return {
          ...oldQuery,
          where: {
            ...oldQuery.where,
            searchData: { $iLike: `%${searchValue.split(" ").join("%")}%` },
          },
          offset: 0,
        };
      });
    }, DEBOUNCE_TIME_MS);

    return () => {
      clearTimeout(queryHandler);
    };
  }, [searchValue, isSearching]);

  useEffect(() => {
    if (deleteExpenseId && promptResponse) {
      dispatch(
        deleteJobExpense(
          deleteExpenseId,
          query,
          jobQueryId,
          initialJobDetailsQuery,
        ),
      );
      setDeleteExpenseId("");
    }
  }, [deleteExpenseId, promptResponse, query, dispatch, jobQueryId]);

  useEffect(() => {
    if (expenseFile && downloadId !== -1 && expenseFile.id === downloadId) {
      downloadExpenseFile(expenseFile);
      setDownloadId(-1);
      dispatch(setExpenseFile(undefined));
    }
  }, [expenseFile, downloadId, dispatch]);

  const downloadExpenseFile = (file: FileModel) => {
    const anchor = document.createElement("a");
    anchor.href = file.downloadUrl as string;
    anchor.download = file.fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleChageSearch = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    setIsSearching(true);
  };

  const handleClickApplyRangeDate = (dateRange: DateRange) => {
    setQuery((prevQuery) => {
      const oldQuery = cloneDeep(prevQuery);
      const startDate = dateRange.startDate;
      const endDate = dateRange.endDate;

      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);

      return {
        ...oldQuery,
        where: {
          ...oldQuery.where,
          date: { $gte: startDate, $lte: endDate },
        },
      };
    });
  };

  const handleClickDelete = (id: string) => {
    setDeleteExpenseId(id);
    dispatch(
      prompt({
        title: "Delete Expense?",
        message: `Are you sure you want to this Expense?`,
      }),
    );
  };

  const handleClickDownload = (fileId: number) => {
    dispatch(getJobExpenseFile(fileId));
    setDownloadId(fileId);
  };

  const handleClickSort = (sortingValues: string[][]) => {
    setSortingOptions(sortingValues);
    setQuery((prevQuery) => {
      return {
        ...cloneDeep(prevQuery),
        order: sortingValues.length > 0 ? sortingValues : undefined,
      };
    });
  };

  const expensesFilters = (
    <Fragment>
      <Box className={classes.idDateContainer}>
        <Typography
          className={classes.jobId}
          variant="h6"
        >{`Job ID ${jobId}`}</Typography>

        <Box display="flex" justifyContent="flex-end">
          <RangeDatePicker
            maxDate={new Date()}
            onClickApply={handleClickApplyRangeDate}
          />
        </Box>
      </Box>

      <Box className={classes.searchbarTotalContainer}>
        <Box maxWidth="348px" width="100%">
          <TextField
            placeholder="Search by Employee Name or ID"
            margin="normal"
            variant="outlined"
            value={searchValue}
            InputProps={{
              type: "search",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleChageSearch}
          />
        </Box>
      </Box>
    </Fragment>
  );

  return (
    <Fragment>
      {!isDeleteModalOpen && (
        <GlobalModal
          Filters={expensesFilters}
          modalMaxWidth="966px"
          open={isOpen}
          showActionButtons={false}
          title="Job Description History"
          onClickClose={onClickClose}
        >
          <TableCustom
            columns={expensesModalColumns}
            isLoading={isLoading}
            noRowsMessage="There are no jobs description to display yet."
            rows={getExpensesModalRows(
              expenses,
              handleClickDelete,
              handleClickDownload,
            )}
            sortingOptions={sortingOptions}
            onClickSort={handleClickSort}
          />
        </GlobalModal>
      )}
    </Fragment>
  );
}
